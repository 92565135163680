const SEO = gql`
  query SEO {
    landingPageCollection(limit: 1, where: { internalName: "home" }) {
      total
      items {
        seo {
          title
          description
          image {
            title
            description
            url
          }
          keywords
          noindex
          nofollow
        }
      }
    }
  }
`;

export default SEO;
