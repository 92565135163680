import hash from "object-hash";
import { useDataCache, useRouteCache } from "#nuxt-multi-cache/composables";

export const getFormerKey = (serviceName: string, key: string, o?: {}) => {
  if (o) {
    if (typeof o === "object") {
      return `${serviceName}:${key}:${hash(o)}`;
    }
    return `${serviceName}:${key}:${o}`;
  } else {
    return `${serviceName}:${key}`;
  }
};

export const useCacheService = () => {
  const { runWithContext } = useNuxtApp();

  const shortTTL = 5 * 60;
  const longTTL = 10 * 60;
  const pageTTL = 60 * 60;
  const immediateTTL = 1;

  const cacheFallbackForRequestFail = async <T>(
    fn: Function,
    serviceName: string,
    key: string,
    ttl: number,
    o?: {}
  ) => {
    // if (typeof window !== "undefined") return;
    const cacheKey = getFormerKey(serviceName, key, o || {});
    let result = { items: [], total: 0 } as T;

    const { value, addToCache } = await useDataCache<T>(cacheKey);

    if (value) {
      result = value as T;
    } else {
      await runWithContext(async () => {
        result = await fn();
        addToCache(result, undefined, ttl);
      });
    }
    return result;
  };

  const usePageCache = (ttl: number = pageTTL) => {
    useRouteCache((helper) => {
      helper.setMaxAge(ttl).setCacheable();
    });
  };

  const usePostListPageCache = () => {
    useRouteCache((helper) => {
      const query = useRouter().currentRoute.value.query;

      if (query.query) {
        helper.setUncacheable();
      } else if (query.page) {
        helper.setMaxAge(shortTTL).setCacheable();
      } else {
        helper.setMaxAge(pageTTL).setCacheable();
      }
    });
  };

  const usePostPageCache = () => {
    useRouteCache((helper) => {
      const query = useRouter().currentRoute.value.query;
      if (query.preview && query.preview === "true") {
        helper.setUncacheable();
      } else {
        helper.setMaxAge(pageTTL).setCacheable();
      }
    });
  };

  return {
    shortTTL,
    longTTL,
    pageTTL,
    immediateTTL,
    getFormerKey,
    cacheFallbackForRequestFail,
    usePageCache,
    usePostListPageCache,
    usePostPageCache
  };
};
