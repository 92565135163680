import gql from "graphql-tag";

const BRIEF_POST_COLLECTION = gql`
  query BRIEF_POST_COLLECTION(
    $limit: Int
    $skip: Int
    $search: String
    $categories: [String]
    $author: String
    $tag: String
    $publishedOn_gte: DateTime
    $publishedOn_lte: DateTime
  ) {
    pageBlogPostCollection(
      limit: $limit
      skip: $skip
      order: publishedOn_DESC
      where: {
        OR: [{ title_contains: $search }, { content_contains: $search }]
        categories: { slug_in: $categories }
        author: { slug: $author }
        tags: { slug: $tag }
        publishedOn_gte: $publishedOn_gte
        publishedOn_lte: $publishedOn_lte
      }
    ) {
      total
      items {
        title
        slug
        categoriesCollection(where: { slug_in: $categories }) {
          items {
            name
            sys {
              id
            }
          }
        }
        tagsCollection {
          items {
            name
            slug
          }
        }
        featuredMedia {
          description
          url
        }
        excerpt
        publishedOn
        sys {
          id
        }
      }
    }
  }
`;

export default BRIEF_POST_COLLECTION;
