import { useCacheService } from "~/services";
import {
  LANDING_PAGE_QUERY,
  BRIEF_POST_COLLECTION,
  POST_BY_SLUG,
  CATEGORY_COLLECTION,
  AUTHOR_BY_SLUG,
  ASIDE_POST_COLLECTION,
  GET_TAG_BY_SLUG,
  SEO_QUERY
} from "~/apollo/contentful/queries";
import type { IPostPage } from "~/types/contentful/pages";
import type {
  ILandingPageResponse,
  IBriefPostResponse,
  IPostPageResponse,
  ITagsCollectionResponse,
  IBriefPostCollectionResponse
} from "~/types/contentful/responses";
import type { ICollection } from "~/types/contentful/abstract";
import type {
  ICategoriesResponse,
  ICategoryCollectionResponse
} from "~/types/contentful/responses/ICategoriesCollectionResponse";
import type {
  IAuthorCollectionResponse,
  IAuthorResponse
} from "~/types/contentful/responses/IAuthorResponse";
import type { IAsidePostsResponse } from "~/types/contentful/responses";
import type {
  IBasicSlug,
  IFeatureCategory,
  ISeo
} from "~/types/contentful/models";
import type {
  ILandingPageCollectionResponse,
  ISEOResponse
} from "~/types/contentful/responses/ILandingPageResponse";
import { getCategoryListFilter, getErrorMessage } from "~/helpers";
import { mapToCardPostNew, mapToCategory } from "~/mappers/components";
import { CardNewsSecondaryImageQueries } from "~/const/ImageQueriesByMedia";
import { ICategory } from "~/types/page-service";

interface OperationVariables {
  [key: string]: any;
}

enum ApolloClients {
  Contentful = "contentful",
  ContentfulPreview = "contentfulPreview"
}

export const useContentfulQuery = () => {
  const getCardsByCategory = async (
    category: IFeatureCategory
  ): Promise<any> => {
    const categories = useState<Array<ICategory>>("categories");
    const categoriesFilter = getCategoryListFilter(
      categories.value,
      category.slug
    );
    const posts = await getBriefPostList({
      limit: 4,
      categories: categoriesFilter
    });
    const cards = posts.items.map((card: IBriefPostResponse) =>
      mapToCardPostNew(card, CardNewsSecondaryImageQueries, true)
    );
    return cards;
  };

  const { cacheFallbackForRequestFail, pageTTL, shortTTL } = useCacheService();
  const { runWithContext } = useNuxtApp();
  const isPreview = useRoute().query.preview ? true : false;

  const isLoading = ref(false);

  const apolloClient = isPreview
    ? ApolloClients.ContentfulPreview
    : ApolloClients.Contentful;

  const getBriefPostList = async (
    variables: OperationVariables
  ): Promise<ICollection<IBriefPostResponse>> => {
    isLoading.value = true;

    try {
      const { data } = await useAsyncQuery<IBriefPostCollectionResponse>({
        query: BRIEF_POST_COLLECTION,
        clientId: apolloClient,
        variables: {
          ...variables
        }
      });
      return data.value.pageBlogPostCollection;
    } catch (error: unknown) {
      throw new Error(
        "Error while fetching card news post data. " + getErrorMessage(error)
      );
    } finally {
      isLoading.value = false;
    }
  };

  const getSEO = async (): Promise<ISeo> => {
    const handleCacheMiss = async () => {
      const { data } = await useAsyncQuery<
        ILandingPageCollectionResponse<ISEOResponse>
      >({
        query: SEO_QUERY,
        clientId: apolloClient
      });

      return data.value.landingPageCollection.items[0].seo;
    };

    try {
      const data = await cacheFallbackForRequestFail<ISeo>(
        handleCacheMiss,
        "getSEO",
        "seo",
        shortTTL
      );

      return data;
    } catch (error: unknown) {
      throw new Error(
        "Error while fetching seo page data. " + getErrorMessage(error)
      );
    }
  };

  const getLandingPage = async (): Promise<ILandingPageResponse> => {
    try {
      const handleCacheMiss = async () => {
        const { data } = await useAsyncQuery<
          ILandingPageCollectionResponse<ILandingPageResponse>
        >({
          query: LANDING_PAGE_QUERY,
          clientId: apolloClient
        });

        const landing = data.value.landingPageCollection.items[0];

        const model = data.value.landingPageCollection.items[0];
        const categoriesWithCards = model.featureCategoriesCollection.items;

        await runWithContext(async () => {
          categoriesWithCards.forEach(async (c) => {
            const cards = await getCardsByCategory(c);
            c.cards = cards;
          });
        });

        return landing;
      };
      const data = await cacheFallbackForRequestFail<ILandingPageResponse>(
        handleCacheMiss,
        "getLandingPage",
        "landing-page",
        shortTTL
      );

      return data;
    } catch (error: unknown) {
      throw new Error(
        "Error while fetching landing page data. " + getErrorMessage(error)
      );
    }
  };

  const getPostBySlug = async (slug: string): Promise<IPostPage> => {
    try {
      const { data } = await useAsyncQuery<IPostPageResponse>({
        query: POST_BY_SLUG,
        clientId: apolloClient,
        variables: {
          preview: isPreview,
          slug
        }
      });
      return data.value.pageBlogPostCollection.items[0];
    } catch (error: unknown) {
      throw new Error(
        `Error while fetching post by slug ${slug}.` + getErrorMessage(error)
      );
    }
  };

  const getCategoryList = async (): Promise<Array<ICategory>> => {
    try {
      const handleCacheMiss = async () => {
        const { data } = await useAsyncQuery<ICategoryCollectionResponse>({
          query: CATEGORY_COLLECTION,
          clientId: apolloClient
        });
        return data.value.categoryCollection.items;
      };

      const data = await cacheFallbackForRequestFail<
        Array<ICategoriesResponse>
      >(handleCacheMiss, "getCategoryList", "category-list", pageTTL);

      return data
        .map((c) => mapToCategory(c))
        .sort((a: ICategory, b: ICategory) =>
          a.name.toLowerCase() < b.name.toLowerCase()
            ? -1
            : a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : 0
        );
    } catch (error: any) {
      throw new Error("Error while fetching category list. " + error.message);
    }
  };

  const getTagBySlug = async (
    slug: string
  ): Promise<IBasicSlug | undefined> => {
    try {
      const { data } = await useAsyncQuery<ITagsCollectionResponse>({
        query: GET_TAG_BY_SLUG,
        clientId: apolloClient,
        variables: {
          slug
        }
      });
      return data.value.tagCollection.items[0] || undefined;
    } catch (error: unknown) {
      throw new Error("Error while fetch tag. " + getErrorMessage(error));
    }
  };

  const getAuthorBySlug = async (slug: string): Promise<IAuthorResponse> => {
    try {
      const { data } = await useAsyncQuery<IAuthorCollectionResponse>({
        query: AUTHOR_BY_SLUG,
        clientId: apolloClient,
        variables: {
          slug
        }
      });
      return data.value.authorCollection.items[0];
    } catch (error: unknown) {
      throw new Error("Error while fetching Author. " + getErrorMessage(error));
    }
  };

  const getAsidePosts = async (
    variables: OperationVariables
  ): Promise<Array<IAsidePostsResponse>> => {
    try {
      const handleCacheMiss = async () => {
        const { data } = await useAsyncQuery<IPostPageResponse>({
          query: ASIDE_POST_COLLECTION,
          clientId: apolloClient,
          variables: {
            ...variables
          }
        });
        return data.value.pageBlogPostCollection.items;
      };
      const data = await cacheFallbackForRequestFail<
        Array<IAsidePostsResponse>
      >(handleCacheMiss, "getAsidePosts", "latest-post", shortTTL);

      return data;
    } catch (error: unknown) {
      throw new Error(
        "Error while fetching Aside posts. " + getErrorMessage(error)
      );
    }
  };

  return {
    getPostBySlug,
    getBriefPostList,
    getSEO,
    getLandingPage,
    getCategoryList,
    isLoading,
    getAsidePosts,
    getAuthorBySlug,
    getTagBySlug
  };
};
