const LANDING_PAGE_QUERY = gql`
  query LANDING_PAGE_QUERY {
    landingPageCollection(limit: 1, where: { internalName: "home" }) {
      total
      items {
        mainHeaderImage {
          url
          description
        }
        mainHeaderImagesCollection {
          items {
            url
            description
          }
        }
        featureCategoriesCollection(limit: 5) {
          items {
            name
            slug
          }
        }
        featureNewsCollection(limit: 5) {
          items {
            title
            slug
            categoriesCollection(limit: 1) {
              items {
                name
                sys {
                  id
                }
              }
            }
            tagsCollection {
              items {
                name
                slug
              }
            }
            featuredMedia {
              description
              url
            }
            excerpt
            publishedOn
            sys {
              id
            }
          }
        }
      }
    }
  }
`;

export default LANDING_PAGE_QUERY;
